import "./App.css";
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import Header from "../src/components/Header/Header";
import Footer from "../src/components/Footer/Footer";
import Home from "../src/containers/Home/Home";
import About from "../src/containers/About/About";
import Products from "../src/containers/Products/Products";
import News from "../src/containers/News/News";
// import NewsCategory1 from "../src/containers/News/NewsCategory1";
import NewsCategory2 from "../src/containers/News/NewsCategory2";
import NewsCategory3 from "../src/containers/News/NewsCategory3";
import NewsCategory4 from "../src/containers/News/NewsCategory4";
import NewsCategory5 from "../src/containers/News/NewsCategory5";
import Contacts from "./containers/Contacts/Contacts";
import NewsCategory6 from "./containers/News/NewsCategory6";

function App() {
  return (
    <Router>
      <div>
        <Header />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/products" element={<Products />} />
          <Route path="/news" element={<News />} />
          <Route path="news/*" element={<News />} />
          <Route path="/contacts" element={<Contacts />} />
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
