import React, { useState, useEffect } from "react";
import background from "../../assets/images/background.png";
import book from "../../assets/images/companion/icons8-book.png";
import education1 from "../../assets/images/companion/icons8-education-1.png";
import education from "../../assets/images/companion/icons8-education.png";
import lesson from "../../assets/images/companion/icons8-lesson.png";
import parent from "../../assets/images/companion/icons8-parent.png";
import school from "../../assets/images/companion/icons8-school.png";
import student from "../../assets/images/companion/icons8-student.png";
import teacher from "../../assets/images/companion/icons8-teacher.png";
import LogoSlider from "./components/LogoSlider";

import school1 from "../../assets/images/Schools/Home/1.png";
import school2 from "../../assets/images/Schools/Home/2.png";
import school3 from "../../assets/images/Schools/Home/3.png";
import school4 from "../../assets/images/Schools/Home/4.png";
import school5 from "../../assets/images/Schools/Home/5.png";
import school6 from "../../assets/images/Schools/Home/6.png";
import school7 from "../../assets/images/Schools/Home/7.png";
import school8 from "../../assets/images/Schools/Home/8.png";
import school9 from "../../assets/images/Schools/Home/9.png";
import school10 from "../../assets/images/Schools/Home/10.png";
import { Link } from "react-router-dom";
import ViewMoreButton from "../../components/ViewMore";
import callApi from "../../services/apiCaller";
import swal from "sweetalert2";

// import { fetchData } from '../services/api';

function Home() {
  const logos = [
    school1,
    school2,
    school3,
    school4,
    school5,
    school6,
    school7,
    school8,
    school9,
    school10,
  ];
  const [listNews, setListNews] = useState([]);
  useEffect(() => {
    getNewsList();
  }, []);

  const getNewsList = async () => {
    var apiBaseUrl =
      process.env.REACT_APP_DOMAIN_API +
      "/gd/v3/h5p/elearning_news?page_id=1&per_page=3&keyword=";
    await callApi(apiBaseUrl, "GET", null)
      .then(function (response) {
        if (response === false || response === undefined) {
          return false;
        }
        if (
          response !== undefined &&
          response.data.data.records !== undefined
        ) {
          setListNews(response.data.data.records);
        }
      })
      .catch(function (error) {
        // config.hideLoader();
        console.log(error);
      });
  };

  return (
    <div className="flex flex-col min-h-screen bg-sky-50">
      <div className="flex-grow relative z-0">
        <img
          className="w-full h-full object-cover min-h-40 lg:min-h-96"
          src={background}
          alt="Background"
        />
      </div>
      <div className="relative z-10 mt-2 px-4 lg:px-24">
        <div id="products" className="ct-sub-container-home">
          <div className="flex flex-row justify-between">
            <h3 className="ct-text-title">
              {/* Sản phẩm tiêu biểu */}
              Giới thiệu
              <span className="ct-underline-title"></span>
            </h3>
            <ViewMoreButton to={"/about"} />
          </div>
          <div className="flex flex-col lg:flex-row gap-5 mt-4 mb-3">
            <div className="lg:basis-1/2 mx-auto text-justify text-lg 2xl:text-4xl">
              Hệ thống LMS 360 e-Learning là phần mềm tiên phong phục vụ lĩnh
              vực chuyển đổi số trong dạy, học và kiểm tra đánh giá, tích hợp
              nhiều tính năng thuận tiện nhất đối với người dùng. Hệ thống được
              thiết kế theo mô hình quản lý cấp Sở - Phòng - Hiệu trưởng - Giáo
              viên - Học sinh - Phụ huynh. Phần mềm kết nối người học với những
              bài giảng tương tác chuyên sâu, chất lượng thông qua các giai đoạn
              từ chuẩn bị bài, các hoạt động trên lớp, kiểm tra đánh giá. Giúp
              giáo viên xây dựng các học liệu số tương tác, xây dựng các bài
              kiểm tra đánh giá đa dạng. Có thể giám sát toàn bộ quá trình học
              tập của học sinh, tạo điều kiện thuận lợi để nhà trường tổ chức
              thành công mô hình dạy học trực tiếp kết hợp trực tuyến.
            </div>
            <div className=" h-auto w-full md:h-96 md:w-auto lg:basis-1/2 lg:mx-auto lg:h-auto">
              <video
                className="w-full h-full"
                src="https://cdnc.lms360.edu.vn/2024-04-09/backkhoa/lms360.mp4"
                controls
              ></video>
              {/* <iframe
                src={lms360Video}
                title="LMS 360 Video Introduction"
                allowFullScreen
                className="w-full h-full"
                muted
                sandbox
              /> */}
            </div>
          </div>
        </div>
        <div id="News" className="ct-sub-container-home">
          <div className="flex flex-row justify-between">
            <h3 className="ct-text-title">
              Tin tức
              <span className="ct-underline-title"></span>
            </h3>
            <ViewMoreButton to={"/news"} />
          </div>
          <div className="flex flex-col lg:flex-row mt-4 mb-3">
            {/* Bài viết thứ nhất */}
            <Link
              to={`news?newsId=${listNews[0]?.id}`}
              className=" lg:basis-7/12 w-full lg:relative flex flex-col group p-3 border-b lg:border-r lg:border-b-0"
            >
              <div className="overflow-hidden" style={{height: '550px'}}>
                <img
                  alt="News images"
                  className="w-full h-full object-cover hover:scale-110 transition duration-500 cursor-pointer"
                  src={listNews[0]?.news_avatar[0]}
                />
              </div>
              <div className="text-black text-center mt-3 lg:mt-0 lg:text-left">
                <p className="text-lg font-bold text-left 2xl:text-3xl group-hover:underline news-slider-text-title">
                  {listNews[0]?.news_title}
                </p>
                <p className=" py-1 text-lg text-left 2xl:text-3xl news-slider-text-description">
                  {listNews[0]?.news_description}
                </p>
              </div>
            </Link>

            {/* Các bài viết tiếp theo */}
            <div className=" lg:basis-5/12 flex flex-col p-3">
              {listNews.map((item, index) => (
                <>
                  {index !== 0 ? (
                    <Link
                      key={index}
                      to={`news?newsId=${item?.id}`}
                      className={`w-full relative flex flex-col group ${
                        index < 1 ? "border-b pb-3" : ""
                      }`}
                    >
                      <div className="overflow-hidden" style={{maxHeight: '300px'}}>
                        <img
                          alt="News images"
                          className="w-full h-full object-cover hover:scale-110 transition duration-500 cursor-pointer"
                          src={item.news_avatar[0]}
                        />
                      </div>
                      <div className="text-black text-center">
                        <p className="text-lg font-bold text-left group-hover:underline 2xl:text-3xl news-slider-text-title">
                          {item.news_title}
                        </p>
                      </div>
                    </Link>
                  ) : (
                    ""
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
        <div id="companion" className="ct-sub-container-home">
          <div>
            <h3 className="ct-text-title">
              đơn vị đồng hành
              <span className="ct-underline-title"></span>
            </h3>
          </div>
          <div className="gap-5 mt-4 mb-3 mx-2">
            <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-6">
              {[
                {
                  imgSrc: education1,
                  text: "10 Sở GD&ĐT",
                  color: "bg-[#FD0909D4]",
                },
                {
                  imgSrc: education,
                  text: "130 Phòng GD&ĐT",
                  color: "bg-[#2A773B]",
                },
                {
                  imgSrc: school,
                  text: "800 Trường học",
                  color: "bg-[#367AE2]",
                },
                {
                  imgSrc: teacher,
                  text: "60.000 Giáo viên",
                  color: "bg-[#2AA245]",
                },
                {
                  imgSrc: student,
                  text: "1.000.000 Học sinh",
                  color: "bg-[#E2A836]",
                },
                {
                  imgSrc: parent,
                  text: "1.900.000 Phụ huynh",
                  color: "bg-[#367AE2]",
                },
                {
                  imgSrc: book,
                  text: "900.000 Khóa học",
                  color: "bg-[#9351E8]",
                },
                {
                  imgSrc: lesson,
                  text: "5.000.000 Bài giảng Elearning",
                  color: "bg-[#E99494]",
                },
              ].map((item, index) => (
                <div
                  key={index}
                  className={`ct-item-companion ${item.color} hover:transform hover:scale-110 transition-transform duration-300`}
                >
                  <img
                    className="ct-img-item-companion w-1/3 h-auto"
                    src={item.imgSrc}
                    alt={`Logo ${index + 1}`}
                  />
                  <p className="ct-text-item-companion">{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          id="customer"
          className="ct-sub-container-home flex flex-col justify-center align-middle"
        >
          <div className="flex justify-center align-middle">
            <h3 className="ct-text-title">
              Khách hàng nổi bật
              <span className="ct-underline-title"></span>
            </h3>
          </div>
          <div className="gap-5 mt-4 mb-3 flex flex-col justify-center">
            <span className="text-center lg:mx-56 text-xl mt-3 mb-5 2xl:text-4xl">
              Với sự tận tâm, trách nhiệm, sáng tạo, chúng tôi mang đến cho hệ
              thống giáo dục các sản phẩm chất lượng và sự hài lòng cao nhất
            </span>
            <div className="px-4">
              <LogoSlider logos={logos} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
