import React from "react";

const CompanyMap = () => {
  return (
    <div className="w-full h-auto">
      <iframe
        title="Company map"
        className="w-full h-40 md:h-60 lg:h-80 2xl:h-96 mt-10 lg:mt-4"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d979.889795454017!2d106.62957106944245!3d10.76841689933625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f0001282037%3A0xeaa9c697eb99327f!2zQ8O0bmcgVHkgVE5ISCBQaMOhdCBUcmnhu4NuIHbDoCDhu6huZyBE4bulbmcgQ8O0bmcgTmdo4buHIELDoWNoIEtob2E!5e0!3m2!1svi!2s!4v1705630465978!5m2!1svi!2s"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default CompanyMap;
