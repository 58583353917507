import React from "react";
import img1 from "../../assets/images/About/1.png";
import img2 from "../../assets/images/About/2.jpg";
import img3 from "../../assets/images/About/3.png";
import img4 from "../../assets/images/About/4.jpg";
// import { fetchData } from '../services/api';

function About() {
  const mission = [
    {
      title: "Phát Triển  Nền Tảng Giáo Dục Thông Minh",
      content:
        "Chúng tôi cam kết xây dựng các nền tảng giáo dục thông minh, giúp giáo viên dễ dàng quản lý và tùy chỉnh nội dung học tập, cũng như giúp học sinh tận dụng tối đa kiến thức và kỹ năng của họ.",
    },
    {
      title: "Đào Tạo và Hỗ Trợ Giáo Viên",
      content:
        "Chúng tôi cung cấp các chương trình đào tạo chuyên sâu và hỗ trợ liên tục cho giáo viên, giúp họ nắm bắt được những xu hướng mới nhất trong giáo dục và áp dụng chúng một cách linh hoạt và hiệu quả.",
    },
    {
      title: "Nâng Cao Kỹ Năng Ứng Dụng Công Nghệ cho Học Sinh",
      content:
        "Chúng tôi hướng đến việc phát triển các giải pháp giáo dục cá nhân hóa, kích thích sự sáng tạo và phát triển kỹ năng mềm cần thiết cho thế kỷ 21.",
    },
    {
      title: "Tạo Ra Nền Tảng Học Tập Đa Dạng",
      content:
        "Sứ mệnh của chúng tôi là phát triển nền tảng giáo dục đa dạng, nơi mà học sinh có thể trải nghiệm nhiều phương pháp học khác nhau, từ học trực tuyến đến học tại lớp, để phát triển mọi khía cạnh của bản thân họ.",
    },
    {
      title: "Đổi Mới trong Đánh Giá và Đo Lường",
      content:
        "Chúng tôi đặt ra mục tiêu thay đổi cách đánh giá và đo lường thành tích học tập. Sứ mệnh của chúng tôi là xây dựng các phương pháp đánh giá đa chiều, tập trung vào việc đo lường khả năng sáng tạo, tư duy phản biện, và kỹ năng giải quyết vấn đề.",
    },
    {
      title: "Hỗ Trợ Giao Việc Giảng Dạy Giữa Người Học và Người Dạy",
      content:
        "Chúng tôi hướng đến việc phát triển giải pháp công nghệ giúp hỗ trợ mối quan hệ giữa giáo viên và học sinh. Sứ mệnh của chúng tôi là tạo ra các công cụ tương tác để tăng cường giao tiếp, tạo cầu nối giữa người học và người dạy, tạo nên môi trường học tập năng động và thân thiện.",
    },
    {
      title: "Chú Trọng Đến An Sinh Tinh Thần",
      content:
        "Chúng tôi không chỉ tập trung vào khía cạnh học thuật mà còn coi trọng đến phát triển toàn diện của học sinh. Sứ mệnh của chúng tôi là đảm bảo rằng giáo dục không chỉ giúp học sinh thành công trong công việc mà còn xây dựng nền tảng cho sự phát triển tinh thần và tâm hồn của họ.",
    },
  ];

  return (
    <div className="flex flex-col min-h-screen bg-sky-50">
      <div className="relative z-10 mt-2 px-5 lg:px-24 mb-10">
        <div className="ct-sub-container-home">
          <div className="flex flex-row justify-start mb-5">
            <h3 className="ct-text-title">
              {/* Sản phẩm tiêu biểu */}
              Về chúng tôi
              <span className="ct-underline-title"></span>
            </h3>
          </div>
          <div className="">
            <div className=" border-b border-b-slate-200 pb-2">
              <p className=" text-2xl lg:text-3xl 2xl:text-4xl text-[#1A49A5] font-semibold">
                Tầm nhìn
              </p>
              <p className="text-xl lg:text-2xl 2xl:text-3xl mb-8">
                Tầm nhìn của chúng tôi là xây dựng một hệ thống giáo dục hiện
                đại, tiên tiến và công bằng, nơi mà mọi học sinh, sinh viên có
                cơ hội bình đẳng để phát triển toàn diện. Chúng tôi nhìn thấy
                một Việt Nam với thế hệ trẻ được trang bị những kỹ năng cần
                thiết để tự tin đối mặt với những thách thức của thế giới ngày
                nay và tương lai
              </p>
              <div className=" mx-2 md:mx-5 lg:mx-10 2xl:mx-20">
                <div className="lg:grid lg:grid-cols-2 lg:gap-5 my-5 rounded-xl bg-sky-100 shadow-xl shadow-sky-50 p-2 lg:pl-5">
                  <div className="flex items-center justify-center">
                    <img className="rounded-lg w-full" src={img1} alt="Anh 1" />
                  </div>
                  <div className="p-5 flex flex-col justify-center items-center">
                  <div
                      className="flex text-[#0FB0EC] items-center justify-center lg:justify-start m-auto p-2 lg:p-0"
                      style={{ fontFamily: "serif" }}
                    >
                      <p className=" lg:text-6xl xl:text-8xl font-bold mr-4">
                        1
                      </p>
                      <p className=" lg:text-3xl xl:text-4xl font-semibold">
                        Giáo Dục Hiện Đại
                      </p>
                    </div>
                    <p className="text-md lg:text-xl xl:text-3xl text-justify p-2 lg:p-5">
                      Chúng tôi cam kết đưa giáo dục Việt Nam lên tầm cao mới
                      bằng cách áp dụng công nghệ tiên tiến nhất để tạo ra một
                      môi trường học tập linh hoạt, sáng tạo và phản ánh xu
                      hướng toàn cầu.
                    </p>
                  </div>
                </div>
                <div className="lg:grid lg:grid-cols-5 my-5 rounded-xl bg-sky-100 shadow-xl shadow-sky-50 p-2 lg:pl-5">
                  <div className=" col-span-3 lg:grid lg:grid-cols-2 lg:gap-2">
                    <img className="rounded-lg m-auto" src={img2} alt="Anh 2" />
                    <div
                      className="flex text-[#0FB0EC] items-center justify-center lg:justify-start m-auto p-2 lg:p-0"
                      style={{ fontFamily: "serif" }}
                    >
                      <p className=" lg:text-6xl xl:text-8xl font-bold mr-4">
                        2
                      </p>
                      <p className=" lg:text-3xl xl:text-4xl font-semibold">
                        Tích Hợp <br className=" hidden lg:block" /> Công Nghệ
                      </p>
                    </div>
                  </div>
                  <div className="col-span-2 p-2 lg:p-5 flex justify-center items-center">
                    <p className="text-md lg:text-xl xl:text-3xl text-justify ">
                      Chúng tôi tận dụng sức mạnh của công nghệ để tối ưu hóa
                      quá trình giảng dạy và học tập. Hệ thống giáo dục kết hợp
                      giữa nền tảng số hóa, sự tương tác, học trực tuyến và học
                      liệu số tạo ra trải nghiệm học tập đa dạng và phong phú.
                    </p>
                  </div>
                </div>
                <div className="lg:grid lg:grid-cols-5 lg:gap-5 my-5 rounded-xl bg-sky-100 shadow-xl shadow-sky-50 p-2 lg:pl-5">
                  <div className=" col-span-2">
                    <img className="rounded-lg m-auto" src={img4} alt="Anh 4" />
                  </div>
                  <div className="col-span-3 p-5 flex flex-col justify-center items-center">
                    <div
                      className="flex text-[#0FB0EC] items-center justify-center lg:justify-start m-auto p-2 lg:p-0"
                      style={{ fontFamily: "serif" }}
                    >
                      <p className=" lg:text-6xl xl:text-8xl font-bold mr-4">
                        3
                      </p>
                      <p className=" lg:text-3xl xl:text-4xl font-semibold">
                        Bình Đẳng Và Tiếp <br className=" hidden lg:block" />{" "}
                        Cận Mọi Người
                      </p>
                    </div>
                    <p className="text-md lg:text-xl xl:text-3xl text-justify p-2 lg:p-5">
                      Tầm nhìn của chúng tôi không chỉ là về sự tiến bộ mà còn
                      về sự công bằng. Chúng tôi nỗ lực để mọi học sinh, sinh
                      viên không phụ thuộc vào vùng miền hay điều kiện kinh tế,
                      đều có quyền lợi và cơ hội bình đẳng để truy cập vào hệ
                      thống giáo dục chất lượng cao
                    </p>
                  </div>
                </div>
                <div className="my-5 flex flex-col rounded-xl bg-sky-100 shadow-xl shadow-sky-50 p-2 lg:pl-5">
                  <div
                    className="flex text-[#0FB0EC] items-center justify-center lg:justify-start m-auto p-2 lg:p-0"
                    style={{ fontFamily: "serif" }}
                  >
                    <p className=" lg:text-6xl xl:text-8xl font-bold mr-4">4</p>
                    <p className=" lg:text-3xl xl:text-4xl font-semibold">
                      Sự Hỗ Trợ Cho Hệ Thống Giáo Dục Toàn Cầu
                    </p>
                  </div>
                  <div className=" lg:grid lg:grid-cols-2 pb-5 lg:gap-5">
                    <img className="rounded-lg m-auto" src={img3} alt="Anh 3" />
                    <p className="text-md lg:text-xl xl:text-3xl text-justify p-2 lg:p-5">
                      Chúng tôi nhìn xa hơn biên giới quốc gia, hướng đến việc
                      cung cấp các giải pháp chuyển đổi số cho các hệ thống giáo
                      dục trên toàn thế giới. Tầm nhìn của chúng tôi là thúc đẩy
                      sự cộng tác và chia sẻ kiến thức, giúp các quốc gia phát
                      triển mô hình giáo dục hiện đại và tiến bộ.
                    </p>
                  </div>
                </div>
                <div className=" flex flex-col-reverse lg:grid lg:grid-cols-2 lg:lg:gap-5 my-5 rounded-xl bg-sky-100 shadow-xl shadow-sky-50 p-2 lg:pl-5">
                  <div className="p-2 lg:p-5 flex justify-center items-center">
                    <p className="text-md lg:text-xl xl:text-3xl text-justify">
                      Chúng tôi cam kết đứng đầu trong việc nghiên cứu và phát
                      triển công nghệ mới, như trí tuệ nhân tạo, thực tế ảo, và
                      blockchain để đảm bảo rằng giáo dục của chúng ta không chỉ
                      phản ánh xu hướng hiện tại mà còn dựa vào những đổi mới
                      tiên tiến.
                    </p>
                  </div>
                  <div
                    className="flex text-[#0FB0EC] items-center justify-center lg:justify-start m-auto p-2 lg:p-0"
                    style={{ fontFamily: "serif" }}
                  >
                    <p className=" lg:text-6xl xl:text-8xl font-bold mr-4">5</p>
                    <p className=" lg:text-3xl xl:text-4xl font-semibold">
                      Chủ Động Tìm Kiếm <br className=" hidden lg:block" /> Công
                      Nghệ Mới
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p className=" text-2xl lg:text-3xl 2xl:text-4xl text-[#1A49A5] font-semibold">
                Sứ mệnh
              </p>
              <p className="text-xl lg:text-2xl 2xl:text-3xl mt-4 mb-8">
                Sứ mệnh của chúng tôi là đóng góp vào sự phát triển toàn diện
                của cộng đồng giáo dục Việt Nam thông qua việc cung cấp các giải
                pháp công nghệ chuyển đổi số tiên tiến, linh hoạt và tích hợp để
                nâng cao chất lượng giáo dục.
              </p>
              <div className="rounded-xl bg-sky-100 shadow-xl shadow-sky-50 p-4">
                {mission.map((item, num) => (
                  <div className=" border-b border-blue-200">
                    <p className="text-[#2947A9] text-lg lg:text-xl 2xl:text-2xl my-3 font-bold border-l-2 border-l-[#2947A9] pl-3">{item.title}</p>
                    <p className="ml-4 text-lg lg:text-xl 2xl:text-2xl ">{item.content}</p>
                  </div>
                ))}
              </div>
              <p className="text-xl lg:text-2xl 2xl:text-3xl mt-8">
                Thông qua tầm nhìn và sứ mệnh này, chúng tôi hy vọng có thể là
                một đối tác đáng tin cậy, đồng hành với Ngành giáo dục Việt Nam
                trên con đường chuyển đổi số và phát triển bền vững. Chúng tôi
                mong muốn hợp tác chặt chẽ với các bên liên quan như trường học,
                tổ chức giáo dục, và cộng đồng để cùng nhau định hình một tương
                lai giáo dục tốt đẹp hơn cho Việt Nam.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
