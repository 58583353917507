import axios from "axios";
import swal from "sweetalert2";

export default async function callApi(
  endpoint,
  method = "GET",
  body,
  header = null,
  withCredentials
) {
  const status = navigator.onLine;
  if (status === false) {
    swal(
      "Đường truyền mạng không ổn định. Vui lòng xem lại đường truyền kết nối mạng."
    );
    return false;
  }

  return await axios({
    method: method,
    url: endpoint,
    data: body,
    headers: header,
  }).catch(function (error) {
    if (error.response) {
      if (error.response.status === 401) {
        // 401: re login
        swal({
          text: error.response.data.errors[0].message,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          window.location.href = "/dang-nhap";
        });
      }
      if (
        error.response.status === 400 ||
        error.response.status === 500 ||
        error.response.status === 403
      ) {
        if (error.response.data === "") {
          return false;
        }
        var msg = error.response.data.errors[0].message;
        switch (msg) {
          case "code not valid":
            msg = "Mã xác thực không đúng.";
            break;
          case "password must contain at least eight characters, including at least one number, one uppercase English letter, one lower case English letter and one special character":
            msg =
              "Mật khẩu phải chứa ít nhất 8 ký tự, bao gồm ít nhất một số, một chữ cái viết hoa, một chữ cái viết thường và một ký tự đặc biệt. Vui lòng thử lại.";
            break;
        }

        swal("", msg, "warning", {
          button: "OK",
        });
        return false;
      }
    }
  });
}
