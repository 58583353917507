import React from "react";
// import { fetchData } from '../services/api';
import News1Crop from "../../assets/images/News/News1Crop.png";
import News3 from "../../assets/images/News/News2.png";
import News2 from "../../assets/images/News/News3.png";
import News4 from "../../assets/images/News/NewsCategory51.png";
import img1 from "../../assets/images/News/NewsCategory41.jpeg";
import img2 from "../../assets/images/News/NewsCategory42.png";
import img3 from "../../assets/images/News//News4.jpeg";

import NewsList from "./components/NewsList";

function NewsCategory5() {
  const newsList = [
    {
      title:
        "Bách Khoa tập huấn chương trình chuyển đổi số trong dạy, học, kiểm tra đánh giá và xây dựng học liệu số e-Learning cho trường THPT chuyên Lê Hồng Phong TPHCM",
      img: News1Crop,
      content:
        "Trường THPT chuyên Lê Hồng Phong toạ lạc tại Quận 5, Thành phố Hồ Chí Minh. Trường được thành lập năm 1927 và là một trong những trường Trung học đầu tiên được thành lập tại Sài Gòn, với tên gọi ban đầu là trường Trung học Pétrus Trương Vĩnh Ký. Sau năm 1975, trường đổi tên thành THPT chuyên Lê Hồng Phong ...",
      link: "/news/tap-huan-thpt-le-hong-phong",
    },
    {
      title: "Tập huấn LMS 360 e-Learning tại trường THPT Lê Quý Đôn",
      img: News2,
      content:
        "E - Learning nhằm tạo ra sự thuận lợi, giúp học sinh có thể học tập ở mọi lúc, mọi nơi. Đồng thời, tạo thêm sự thích thú khi học tập. Sự triển khai hệ thống LMS 360 đã được sự đồng thuận của Sở GD và Phòng ĐT, đồng thời cũng được kết nối trực tiếp với cơ sở dữ liệu ngành. Và có nhiều sự hợp tác và khách hàng tiêu biểu, trong đó Bách Khoa Tech đã được đồng hành với trường THPT Lê Quý Đôn...",
      link: "/news/tap-huan-thpt-le-quy-don",
    },
    {
      title: "Lãnh đạo UBND TPHCM tham quan Bách Khoa Tech",
      img: News3,
      content:
        "Tech4Life là sự kiện chào mừng ngày Chuyển đổi số Quốc gia 2023. Đây là sự kiện thường niên, quy mô quốc gia và quốc tế, do Sở Thông tin Truyền thông Tp. Hồ Chí Minh và Hiệp hội Phần mềm và Dịch vụ CNTT Việt Nam (VINASA) tổ chức thường niên bắt đầu từ năm 2022...",
      link: "/news/lanh-dao-ubnd-tphcm-tham-quan-bach-khoa-tech",
    },
    {
      title:
        "Hơn 1.200 giáo viên, cán bộ quản lý tập huấn ứng dụng AI vào dạy học",
      img: News4,
      content:
        "1.200 giáo viên, cán bộ quản lý các trường tiểu học, THCS trên địa bàn quận 3 vừa được tập huấn ứng dụng trí tuệ nhân tạo (AI) vào công tác giảng dạy và xây dựng học liệu số....",
      link: "/news/hang-ngan-giao-vien-duoc-tap-huan-tri-tue-nhan-tao",
    },
  ];

  return (
    <div className="flex flex-col min-h-screen bg-sky-50">
      <div className="relative z-10 mt-2 px-5 lg:px-24 mb-10">
        <div className="ct-sub-container-home">
          <div className="flex flex-row justify-start mb-5">
            <h3 className="ct-text-title">
              Bản tin bách khoa
              <span className="ct-underline-title"></span>
            </h3>
          </div>
          <div className="p-5 pt-1 text-justify lg:text-left">
            <p className="font-bold text-2xl mb-5 ">
              Tập đoàn Khoa học Công nghệ Bách Khoa tham gia chuyển đổi số tại
              TP HCM
            </p>
            <p className="text-lg italic text-justify">
              (TPO) - Việc ứng dụng công nghệ thông tin trong giáo dục được xác
              định là nhiệm vụ trọng tâm nhằm đổi mới phương pháp giảng dạy, học
              tập và quản lý, góp phần nâng cao chất lượng các hoạt động giáo
              dục
            </p>
            <p className="text-lg text-justify">
              Ảnh hưởng to lớn của đại dịch COVID-19 đã thúc đẩy nhanh hơn quá
              trình chuyển đổi số trong lĩnh vực giáo dục. Tuy nhiên, khái niệm
              và định nghĩa về quá trình chuyển đổi số giáo dục không chỉ giới
              hạn ở việc dạy và học trực tuyến trong thời kỳ giãn cách, mà nó
              tiếp tục phát triển mạnh mẽ hơn và chuyển đổi số trong giáo dục là
              một trong những chương trình chuyển đổi số quốc gia.
              <br />
              Ông Huỳnh Quốc Thắng, Tổng Giám đốc Tập đoàn Khoa học Công nghệ
              Bách Khoa cho rằng chuyển đổi số trong giáo dục là ứng dụng công
              nghệ kỹ thuật số và hệ thống thông tin internet vào lĩnh vực giáo
              dục để nâng cao chất lượng giảng dạy, học tập và quản lý giáo dục.
              Bao gồm đổi mới phương pháp giảng dạy, cải tiến các thiết bị, dụng
              cụ hỗ trợ học tập, nâng cao trải nghiệm của học sinh, sinh viên và
              người tham gia đào tạo.
            </p>
            <figure className="h-full lg:w-3/4 my-5 mx-auto object-cover rounded-2xl">
              <img src={img1} alt="Chuyển đổi số" />
              {/* <figcaption className="text-center italic">
                *Bách khoa Tech hướng dẫn thầy cô Trường THPT chuyên Lê Hồng
                Phong thiết kế bài giảng e-Learning
              </figcaption> */}
            </figure>
            <p className="text-lg text-justify">
              Chuyển đổi số giúp tạo ra môi trường học tập nơi mà mọi thứ kết
              nối với nhau. Sự kết hợp mới mẻ của công nghệ và phương pháp giảng
              dạy nhằm thu hẹp khoảng cách địa lý, tạo ra các trải nghiệm học
              tập thú vị, đồng thời tăng cường sự tương tác của mọi người. Tuy
              nhiên, công tác chuyển đổi số trong giáo dục cũng đặt ra một số
              thách thức, bao gồm việc đảm bảo truy cập đồng thời vào hệ thống,
              đào tạo cho giáo viên và hỗ trợ cho học sinh, đảm bảo an toàn và
              bảo mật thông tin trong môi trường số.
              <br />
              Theo ông Thắng, việc tận dụng sức mạnh của công nghệ để tối ưu hóa
              quá trình giảng dạy và học tập đang được đẩy mạnh trên phạm vi cả
              nước. Tại TP HCM, Công ty Công nghệ Bách Khoa được Sở Giáo dục và
              Đào tạo thành phố cho phép triển khai chương trình chuyển đổi số
              đến các trường học từ năm 2023.
              <br />
              Đến nay, các sản phẩm dịch vụ của Công ty Công nghệ Bách Khoa đã
              triển khai sâu rộng trên địa bàn thành phố và được các trường đánh
              giá cao. Hệ thống giáo dục của Công ty Công nghệ Bách Khoa kết hợp
              giữa nền tảng số hóa, sự tương tác, học trực tuyến và học liệu số
              tạo ra trải nghiệm học tập đa dạng và phong phú trong suốt quá
              trình dạy và học.
            </p>
            <figure className="h-full lg:w-3/4 my-5 mx-auto object-cover rounded-2xl">
              <img
                src={img2}
                alt="Bách Khoa tập huấn chương trình chuyển đổi số về phương pháp giảng dạy và học tập tại Phòng GD&ĐT Quận 3 TPHCM"
              />
              <figcaption className="text-center italic">
                *Bách Khoa tập huấn chương trình chuyển đổi số về phương pháp
                giảng dạy và học tập tại Phòng GD&ĐT Quận 3 TPHCM
              </figcaption>
            </figure>
            <p className="text-lg text-justify">
              Chúng tôi mong muốn cùng với ngành giáo dục hướng đến một hệ thống
              giáo dục hiện đại, tiên tiến và công bằng, nơi mà mọi học sinh,
              sinh viên có cơ hội bình đẳng để phát triển toàn diện" - ông Thắng
              cho biết.
              <br />
              Mục tiêu của Tập đoàn Khoa học Công nghệ Bách Khoa trong chuyển
              đổi số là tạo ra nhiều cơ hội và tiềm năng cho giáo dục, giúp nâng
              cao chất lượng học tập và đáp ứng nhu cầu đa dạng của học viên
              trong thời đại kỹ thuật số.
              <br />
              Hệ sinh thái sản phẩm dịch vụ chuyển đổi số của Tập đoàn Khoa học
              Công nghệ Bách Khoa khá đầy đủ cho ngành giáo dục như: Hệ thống
              quản lý học tập trực tuyến (LMS 360 E-learning), giáo trình điện
              tử, hệ thống quản trị trường học, học bạ điện tử, phần mềm kiểm
              định chất lượng giáo dục, phần mềm thi đua khen thưởng – đánh giá
              và tự đánh giá, phần mềm sắp xếp thời khoá biểu, hệ thống điểm
              danh khuôn mặt bằng trí tuệ nhân tạo, phần mềm họp trực tuyến, ứng
              dụng hỗ trợ học tập.
            </p>
            <figure className="h-full lg:w-3/4 my-5 mx-auto object-cover rounded-2xl">
              <img
                src={img3}
                alt="Hệ sinh thái chuyển đổi số của Tập đoàn Khoa học Công nghệ Bách Khoa"
              />
              <figcaption className="text-center italic">
                *Hệ sinh thái chuyển đổi số của Tập đoàn Khoa học Công nghệ Bách
                Khoa
              </figcaption>
            </figure>
            <p className="text-lg text-justify">
              Nhiều chuyên gia cho rằng, với sự tham gia đóng góp của các doanh
              nghiệp xã hội hoá như Tập đoàn Khoa học Công nghệ Bách Khoa, công
              cuộc chuyển đổi số trong lĩnh vực giáo dục tại TP HCM sẽ sớm đạt
              được mục tiêu. Được biết, kế hoạch chuyển đổi số đến năm 2025,
              định hướng đến năm 2030, ngành giáo dục TP HCM đặt ra mục tiêu xây
              dựng 50 trường học số trong giai đoạn 2023-2025.
              <br />
              Trong đó, các hoạt động dạy và học đều thông qua hệ thống quản lý
              học tập LMS và thư viện số. Phụ huynh cũng sẽ được kết nối với nhà
              trường thông qua các phần mềm ứng dụng. Tất cả các giải pháp này
              giúp tiết kiệm thời gian và tăng khả năng tương tác trong môi
              trường học đường. Sau 50 trường đầu tiên, TP HCM hướng đến nhân
              rộng mô hình này đến tất cả các trường học, đảm bảo lộ trình
              chuyển đổi số toàn bộ trường học đến năm 2030.
            </p>
          </div>
        </div>
        <div className="ct-sub-container-home">
          <div className="flex flex-row justify-start mb-5">
            <h3 className="ct-text-title">
              Tin khác
              <span className="ct-underline-title"></span>
            </h3>
          </div>
          <NewsList newsList={newsList} />
        </div>
      </div>
    </div>
  );
}

export default NewsCategory5;
