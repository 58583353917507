import React from "react";
import { Link } from "react-router-dom";
import arrowIcon from "../assets/images/icons/Arrow 3.png";

const ViewMoreButton = ({ to, checkBlank = false }) => {
  const commonStyles =
    "bg-[#1A64D4] w-6 h-6 lg:w-8 lg:h-8 flex items-center justify-center rounded-full";

  return (
    <Link
      to={to}
      target={checkBlank ? "_blank" : ""}
      rel={checkBlank ? "noopener noreferrer" : ""}
    >
      <button className="px-2 py-2 flex items-center text-[#1A64D4] text-sm md:text-base uppercase font-bold bg-white hover:bg-sky-50 rounded-lg">
        Xem thêm
        <span className="ml-3 flex items-center">
          <div className={commonStyles}>
            <img
              src={arrowIcon}
              alt="Arrow icon"
              className="text-white h-auto w-5 lg:w-7"
            />
          </div>
        </span>
      </button>
    </Link>
  );
};

export default ViewMoreButton;
