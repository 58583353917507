import React from "react";
import logobkgbwhite from "../../assets/images/logobkgbwhite.jpg";
import logoTiktok from "../../assets/images/socialMedia/tiktok.png";
import logoFacebook from "../../assets/images/socialMedia/facebook.png";
import logoYoutube from "../../assets/images/socialMedia/youtube.png";

function Footer() {
  return (
    <footer className="relative bg-[#367AE2] text-white p-2 lg:p-4 text-center">
      <div className="grid grid-cols-1 gap-5 lg:grid-cols-3">
        <div className="lg:col-span-1">
          <div className="mb-4">
            <div className="mb-2 flex justify-center">
              <img
                src={logobkgbwhite}
                alt="Company Logo"
                className="w-1/2 lg:w-3/5 2xl:w-1/2"
              />
            </div>
            <div className="mb-2 flex justify-center uppercase text-2xl">
              follow chúng tôi
            </div>
            <div className="flex justify-center gap-5">
              <a
                href="https://www.facebook.com/lms360elearning"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={logoFacebook}
                  alt="Facebook Logo"
                  className="h-12 w-12 lg:w-16 lg:h-16"
                />
              </a>
              <a
                href="https://www.tiktok.com/@lms360e_learning"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={logoTiktok}
                  alt="TikTok Logo"
                  className="h-12 w-12 lg:w-16 lg:h-16"
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UCcSI13tN-AW4rTBdJFUf2Ww"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={logoYoutube}
                  alt="YouTube Logo"
                  className="h-12 w-12 lg:w-16 lg:h-16"
                />
              </a>
            </div>
          </div>
        </div>

        <div className="lg:col-span-1 text-center xl:px-5 mt-2 lg:mt-0 lg:text-left">
          {/* <h2 className="text-2xl 2xl:text-4xl font-semibold mb-2 uppercase">
            Thông Tin liên hệ
          </h2> */}
          <div className="flex flex-col justify-between">
            <p className="mb-1 lg:mb-3 text-lg 2xl:text-2xl font-bold">
              CÔNG TY CỔ PHẦN TẬP ĐOÀN <br/> KHOA HỌC CÔNG NGHỆ BÁCH KHOA
            </p>
            <p className="mb-1 lg:mb-3 text-lg 2xl:text-2xl">
              23 - 25 Nguyễn Văn Vịnh, Tân Phú, TPHCM
            </p>
            <p className="mb-1 lg:mb-3 text-lg 2xl:text-2xl">
              Email: admin@bachkhoa.tech
            </p>
            <p className="text-lg 2xl:text-2xl">
              ĐT: (0287)102 0246 - 090 303 0246
            </p>
          </div>
        </div>

        <div className="lg:col-span-1 lg:px-6 mt-2 lg:mt-0">
          <div>
            <h2 className="text-2xl 2xl:text-4xl text-center font-semibold mb-4 uppercase">
              Sản Phẩm
            </h2>
            <ul className="lg:text-left text-center underline lg:list-disc">
              <li className="mb-3 text-lg hover:underline 2xl:text-2xl">
                <a
                  href="https://lms360.edu.vn"
                  rel="noreferrer"
                  target="_blank"
                >
                  Hệ thống quản lý học tập LMS360 E-LEARNING
                </a>
              </li>
              <li className="mb-3 text-lg hover:underline 2xl:text-2xl">
                <a
                  href="https://thiduakhenthuong.hcm.edu.vn"
                  rel="noreferrer"
                  target="_blank"
                >
                  Hệ thống thi đua khen thưởng
                </a>
              </li>
              <li className="mb-3 text-lg hover:underline 2xl:text-2xl">
                <a
                  href="https://kiemdinh.hcm.edu.vn"
                  rel="noreferrer"
                  target="_blank"
                >
                  Kiểm định chất lượng giáo giục
                </a>
              </li>
              <li className="text-lg 2xl:text-2xl">Học bạ điện tử</li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
